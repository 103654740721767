<template>
  <div>
    <Preloader v-if='loader'/>
    <h4 class=''>Список учеников</h4>
    <p class='p-0 mb-4'>Синхронизация производится в автоматическом режиме, ежедневно с 03:00 до 03:20 по Московскому
      времени</p>
    <div class='widget'>
      <div class='filter__container'>
        <div class='row mb-3 justify-content-between'>
          <div class='input-group col-9 d-flex'>
            <input
                type='search'
                class='form-control custom__search-input input-custom-search'
                v-model='searchWord'
                id='search-input'
                placeholder="Поиск..."
                @input="debounceSearch"
            >

            <input
                type='search'
                class='form-control custom__search-input input-custom-number'
                v-model='lessonNumber'
                id='search-input'
                placeholder="Количество..."
                @input="debounceLessonNumber"
            >
            <!--                        <div>{{getStudentList[0]}}</div>-->
            <VueMultiselect
                class='custom__multiselect'
                :multiple='true'
                :close-on-select='false'
                :clear-on-select='false'
                :preserve-search='true'
                selectedLabel='Выбран'
                selectLabel='Добавить'
                deselectLabel='Убрать'
                placeholder='Выберите статус студента'
                :tagable='true'
                tagPosition='bottom'
                label='text'
                track-by='value'
                :options='statusOptions'
                v-model='selectedStatus'
                @input='showSelectStatus'
            >
              <template #noOptions>Нет данных</template>
              <template #noResult>Ничего не найдено</template>
            </VueMultiselect>

            <VueMultiselect
                class='custom__multiselect'
                :multiple='true'
                :close-on-select='false'
                :clear-on-select='false'
                :preserve-search='true'
                selectedLabel='Выбран'
                selectLabel='Добавить'
                deselectLabel='Убрать'
                placeholder='Выберите возрастную группу'
                :tagable='true'
                tagPosition='bottom'
                label='text'
                track-by='value'
                :options='getAgeGroups'
                v-model='selectedAgeGroup'
                @input='showSelectAgeGroups'
            >
              <template #noOptions>Нет данных</template>
              <template #noResult>Ничего не найдено</template>
            </VueMultiselect>
            <div class='input-group flex-nowrap input-custom-date'>
              <VCalendar
                  class='teacher-salaries-section-calendar'
                  mode='date'
                  locale="ru"
                  is-range
                  :popover="{ visibility: 'focus' }"
                  :masks="{ input: ['DD.MM.YYYY']}"
                  @input='handleDateSelect'
                  v-model='date'
              >
                <template v-slot='{ togglePopover }'>
                  <div @click='togglePopover()'>
                    <input
                        class='form-control'
                        :class="{'hidden': !date.start}"
                        :value='getDateRange()'
                        readonly
                    />
                  </div>
                </template>
              </VCalendar>
              <button @click="resetDateRange" class="teacher-salaries-section-calendar-reset" title='Сбросить дату'><i
                  class='fa fa-close'></i></button>
            </div>
            <b-checkbox class="my-auto input-custom-group " v-model="isOnlyNegativeSubscription"
                        @change="showOnlyNegativeSubscription">С минусовыми абонементами
            </b-checkbox>
            <b-checkbox class="my-auto input-custom-group" v-model="onlyNotActiveStudents" @change="showOnlyNotActiveStudents">Не
              активные
            </b-checkbox>
          </div>
          <div class='col-3 text-right'>
            <button class='button_add custom__add-student-btn' @click='addData'>Добавить ученика</button>
            <!--            <button class="button_sync ml-3" @click="syncronizeData">Синхронизировать</button>-->
          </div>
        </div>
        <div class='row mb-3 justify-content-between'>
          <div class='input-group col-8 d-flex'>
            <b-link class="btn btn-success" :href="getLinkExcelDownload">Экспортировать в Excel</b-link>
          </div>
        </div>
        <div class='input-group col-4'>

        </div>
      </div>
      <pagination-counter class="text-right my-3" :meta="getPageMeta"/>
      <table class='table table-hover'>
        <thead class='table_head'>
        <tr>
          <th>ФИО ученика</th>
          <th>Статус</th>
          <th>Возраст</th>
          <th>Возрастная группа</th>
          <th>Уровень</th>
          <th>ФИО родителя</th>
          <th>Телефон родителей</th>
          <th>Уведомления</th>
          <th>Часовой пояс</th>
          <th>Действия</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for='student in getStudentList' :key='student.id'
            :class="{'is-negative-subscription' : student.negative_subscription_exist}">
          <td class="student__name">
            {{ student.full_name }}<span
              v-if='student.duplicate_id && student.duplicate_id > 0' title='есть дубликат'
              class='student__badge position-relative top-0'><img src='@/assets/icons/duplicate.svg'
                                                                  alt='' height='20px' width='20px'></span>

          </td>
          <td>
            <span v-if="student.status === 0" class="ml-2 badge bg-warning text-info-light">Саморегестрация</span>
            <span v-if="student.status === 1" class="ml-2 badge bg-success text-info-light">Занимается</span>
            <span v-if="student.status === 2" class="ml-2 badge bg-danger text-info-light">Закончил обучение</span>
            <span v-if="student.status === 3" class="ml-2 badge bg-warning text-info-light">Промо-урок</span>
          </td>
          <td>{{ student.age }}</td>
          <td>{{ getAgeGroupTitle(student.age_group) }}</td>
          <td>{{ student.experience_title }}</td>
          <td>{{ student.parent_name }}</td>
          <td>{{ student.parent_phone }}</td>
          <td>{{ student.notifications_way }}</td>
          <td>{{ student.timezone }}</td>
          <td class='actions_container'>
            <router-link class='leads_table_action_link btn mr-2 btn-primary'
                         :to="{name: 'StudentDetail', params: {id: student.id}}">
              <span class='glyphicon glyphicon-edit'/>
            </router-link>
            <a class='btn mr-2 btn-danger' @click.prevent='deleteRow(student.id, student.full_name)'>
              <span class='fa fa-trash-o delete'></span>
            </a>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div
        class='overflow-auto d-flex justify-content-between align-items-center'
        v-if="getPageMeta && getPageMeta.last_page > 1"
    >
      <b-pagination
          class="paginator my-0"
          :total-rows="getPageMeta.total"
          :per-page="getPageMeta.per_page"
          aria-controls="my-table"
          @page-click='handlePageSelect'
          v-model="currentPage"
      />
      <pagination-counter class="mr-4" :meta="getPageMeta"/>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import DeleteModal from '@/components/Modal/DeleteModal';
import Preloader from '@/components/Preloader';
import VueMultiselect from 'vue-multiselect';
import dayjs from "dayjs";
import {debounce} from "debounce";
import PaginationCounter from "@/components/Pagination/PaginationCounter.vue";
import VCalendar from "v-calendar/lib/components/date-picker.umd";

export default {
  name: 'Students',
  components: {VCalendar, PaginationCounter, Preloader, VueMultiselect},

  async beforeRouteUpdate(to, from, next) {
    this.loader = true;
    await this.loadStudentList(to.query);
    this.loader = false;
    await next();
  },

  data() {
    return {
      date: {
        start: null,
        end: null
        // start: dayjs().set('date', 1).format('YYYY-MM-DD'),
        // end: dayjs().endOf('month').format('YYYY-MM-DD'),
      },
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      isOnlyNegativeSubscription: false,
      onlyNotActiveStudents: false,
      loader: false,
      searchWord: this.$route.query.search || '',
      lessonNumber: this.$route.query.searchLessonNumber || '',

      currentPage: this.$route.query.page || 1,
      statusOptions: [
        {value: '0', text: 'Саморегистрация'},
        {value: '1', text: 'Занимается'},
        {value: '2', text: 'Завершил обучение'},
        {value: '3', text: 'Промо урок'},
      ],
      groupOptions: [
        {value: '0', text: 'Ребенок'},
        {value: '1', text: 'Взрослый'},
      ],
      selectedStatus: [],
      selectedAgeGroup: [],
    };
  },
  computed: {
    ...mapGetters({
      getStudentList: 'students/getStudentList',
      getPageMeta: 'students/getPageMeta',
      getAgeGroups: 'common/getAgeGroups',
    }),
    getLinkExcelDownload() {
      let queries = '';

      for (const [key, value] of Object.entries({...this.$route.query})) {
        if (value !== null) {
          if (queries === '') {
            queries += `?${key}=${value}`
            continue
          }
          queries += `&${key}=${value}`
        }
      }

      return `${this.baseUrl}/crm/students/export-excel${queries}`
    },
  },
  methods: {
    ...mapActions({
      loadStudentList: 'students/loadStudentList',
      findStudents: 'students/findStudents',
      deleteRecord: 'students/deleteRecord',
    }),
    deleteRow(id, name) {
      this.$modal.show(
          DeleteModal,
          {
            recordId: id,
            recordName: name,
            deleteHandler: this.deleteRecord,
          }, {name: 'DeleteModal'},
      );
    },
    addData(e) {
      e.preventDefault();
      this.$router.push({name: 'StudentAdd'});
    },
    toggleLoader() {
      this.loader = !this.loader;
    },
    getAgeGroupTitle(age_group) {
      let result = null
      this.getAgeGroups.forEach(item => {
        if (item.value === age_group) {
          result = item.text
        }
      })

      return result
    },
    getDateRange() {
      if (
          this.date.start && this.date.end
      ) {
        return `с ${this.formatDate(this.date.start)} по ${this.formatDate(this.date.end)}`;
      }
      return `Дата`;
    },
    formatDate(date) {
      return dayjs(date).format('DD.MM.YYYY');
    },
    async resetDateRange() {
      this.date.start = null;
      this.date.end = null;
      let query = Object.assign({}, this.$route.query);
      delete query.date_from;
      delete query.date_to;
      await this.$router.push({ query });
    },
    async search() {
      let query;

      if (this.searchWord && this.searchWord !== '') {
        query = Object.assign({}, this.$route.query, {search: this.searchWord, page: 1});
      } else {
        query = Object.assign({}, this.$route.query, {page: 1});
        delete query.search;
      }

      this.currentPage = 1;
      await this.$router.push({query});
    },
    async showSelectStatus() {
      const ids = this.selectedStatus.map(i => i.value).join(',');
      let query
      if (ids) {
        query = Object.assign({}, this.$route.query, {statuses: ids, page: 1});
      } else {
        query = Object.assign({}, this.$route.query, {page: 1});
        delete query.statuses;
      }
      this.currentPage = 1
      await this.$router.push({query});
    },
    async showSelectAgeGroups() {
      const ids = this.selectedAgeGroup.map(i => i.value).join(',');
      let query
      if (ids) {
        query = Object.assign({}, this.$route.query, {age_groups: ids, page: 1});
      } else {
        query = Object.assign({}, this.$route.query, {page: 1});
        delete query.age_groups;
      }
      this.currentPage = 1
      await this.$router.push({query});
    },
    async showOnlyNegativeSubscription() {
      let query
      if (this.isOnlyNegativeSubscription) {
        query = Object.assign({}, this.$route.query, {
          is_only_negative_subscription: this.isOnlyNegativeSubscription,
          page: 1
        });
      } else {
        query = Object.assign({}, this.$route.query, {page: 1});
        delete query.is_only_negative_subscription;
      }
      this.currentPage = 1
      await this.$router.push({query});
    },
    async showOnlyNotActiveStudents() {
      let query
      if (this.onlyNotActiveStudents) {
        query = Object.assign({}, this.$route.query, {only_not_active_students: this.onlyNotActiveStudents, page: 1});
      } else {
        query = Object.assign({}, this.$route.query, {page: 1});
        delete query.only_not_active_students;
      }
      this.currentPage = 1
      await this.$router.push({query});
    },
    async handlePageSelect(bvEvent, page) {
      if (page) {
        const query = {...this.$route.query, page: page};
        await this.$router.push({query});
      }

      return false;
    },
    debounceSearch: debounce(async function (e) {
      const val = e.target.value;
      let query

      if (this.searchWord && this.searchWord !== '') {
        query = Object.assign({}, this.$route.query, {search: val, page: 1});
      } else {
        query = Object.assign({}, this.$route.query, {page: 1});
        delete query.search;
      }
      this.searchWord = val;
      await this.$router.push({query});
    }, 600),


    debounceLessonNumber: debounce(async function (e) {
      const rawVal = e.target.value; // Preserve the exact input with spaces
      const processedVal = rawVal.trim(); // Use this for processing numbers

      // Split the trimmed input by commas or whitespace, and filter out empty entries
      let numArray = processedVal.split(/[\s,-]+/).filter(item => item !== '');

      // Convert each element to a number and remove any invalid numbers
      numArray = numArray.map(Number).filter(num => !isNaN(num));

      let query;
      // Only add the query if there's one or two valid numbers
      if (numArray.length >= 1 && numArray.length <= 2) {
        query = { ...this.$route.query, searchLessonNumber: numArray, page: 1 };
      } else {
        query = { ...this.$route.query, page: 1 };
        delete query.searchLessonNumber;
      }

      // Store the raw value so spaces aren't lost in the UI
      this.lessonNumber = rawVal;
      await this.$router.push({ query });
    }, 600),

    async handleDateSelect(values) {
      let query
      if (values) {
        this.date.start = dayjs(values.start).format('YYYY-MM-DD')
        this.date.end = dayjs(values.end).format('YYYY-MM-DD')
        query = Object.assign({}, this.$route.query, {date_from: this.date.start, date_to: this.date.end, page: '1'});
      } else {
        query = Object.assign({}, this.$route.query);
        delete query.start;
        delete query.end;
      }

      await this.$router.push({query});
    },
    prepareFilter() {
      if (this.$route.query.date_from && this.$route.query.date_to) {
        // Adjust these lines if your VCalendar expects a different format
        this.date.start = new Date(this.$route.query.date_from);
        this.date.end = new Date(this.$route.query.date_to);
      }
      if (this.$route.query.is_only_negative_subscription === 'true') {
        this.isOnlyNegativeSubscription = true
      }
      if (this.$route.query.only_not_active_students === 'true') {
        this.onlyNotActiveStudents = true
      }

      if (this.$route.query.statuses) {
        const ids = this.$route.query.statuses.split(',');
        let result = [];
        this.statusOptions.forEach((option, index) => {
          ids.forEach((statusId) => {
            if (option.value === statusId) {
              result.push(option)
            }
          })
        })
        this.selectedStatus = [...result];
      }
      if (this.$route.query.age_groups) {
        const ids = this.$route.query.age_groups.split(',');
        let result = [];
        this.groupOptions.forEach((option, index) => {
          ids.forEach((groupId) => {
            if (option.value === groupId) {
              result.push(option)
            }
          })
        })
        this.selectedAgeGroup = [...result];
      }




    }
  },
  created() {
    this.loadStudentList(this.$route.query);
    this.prepareFilter()
  },
};
</script>

<style lang='scss' scoped>
.input-group.d-flex {
  gap: 12px;
}

.input-custom-search{
  width: 160px !important;
}
.input-custom-group{
  //margin-top: 15px !important;
}
.input-custom-number{
  width: 100px !important;
}

.input-custom-date{
  width: 160px !important;
  height: 45px !important;
}
.btn .glyphicon {
  top: 0 !important;
}


.button_add {
  display: inline-block;
  vertical-align: middle;
  border: none;
  font-weight: 400;
  background: #3E86CA;
  color: #fff;
  border-radius: 0.5rem;
  padding: 0 20px;
  font-size: 14px;
  height: 100%;
  transition: .1s;

  &:hover {
    background: #2e6ca7;
    text-decoration: none;
  }
}

.actions_container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.teacher-salaries-section-calendar{
  border-radius: 5px 0 0 5px;
  border: 1px solid #e8e8e8;
  .form-control{
    border: none;
    min-height: 40px;
    color: #29323a;
    display: block;
    background: #fff;
    font-size: 14px;
    cursor: pointer;
  }
}
.teacher-salaries-section-calendar-reset{
  border-radius: 0 5px 5px 0;
  border: 1px solid #e8e8e8;
  border-left: none;
  min-height: 40px;
  color: #1A86D0;
  display: block;
  background: #fff;
  font-size: 14px;
  cursor: pointer;
}
.button_sync {
  @extend .button_add;
  background: #4eb76e;

  &:hover {
    background: darken(#4eb76e, 10);
  }
}

.custom__multiselect {
  width: 25%;

  @media all and (max-width: 1800px) {
    width: 31%;
  }

}

.is-negative-subscription {
  background: #fae5e5;
}

.custom__search-input,
.custom__search-btn,
.custom__add-student-btn {
  height: 43px;
}

.student {
  &__name {
    position: relative;
  }

  &__badge {
    //position: absolute;
    //top: 30px;

    img {
      width: 30px;
    }
  }
}
</style>